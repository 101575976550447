<template>
    <div class="">

        <b-button @click="createNewProfile" class="mb-3" expanded>{{$t('create new project')}}</b-button>

        <div class="block">

            <div class="has-text-centered mt-5" v-if="!profiles || !profiles.length">
                <h4 class="title is-4 has-text-grey-dark">{{$t('Nothing here yet')}}</h4>
            </div>

            <div class="card mb-3" v-for="artisticProfile in profiles"
                 :key="artisticProfile.id">

                <!-- GALLERY / LOGO -->
                <div class="card-image" v-if="artisticProfile.images && Object.keys(artisticProfile.images).length">
                    <a @click="editProfile(artisticProfile)">
                        <b-image custom-class="artistic-profile-item-image is-4by3"
                                 ratio="4by3"
                                 responsive
                                 :src="artisticProfile.images[Object.keys(artisticProfile.images)[0]].original_url"
                                 alt="artistic profile logo"></b-image>
                    </a>

                </div>
                <div class="card-header-title">
                    {{artisticProfile.title ? artisticProfile.title : $t('Project without name')}} <span v-if="!artisticProfile.published"> ( {{$t('draft')}} )</span>

                </div>
                <div class="card-content px-4 pt-0">

                    <!-- SUBSCRIPTION LIMITS -->
                    <div class="block" v-if="!subscriptionDisabled">
                        <div v-for="limit in artisticProfile.limits" :key="limit.id">
                            {{ limit.name}}: {{limit.pivot.value_used}}/{{limit.value}}
                        </div>
                    </div>

                    <!-- FEE -->
                    <div class="block">
                        <div class="heading mb-3">{{$t('fee')}}</div>
                        <b-icon icon="coins" class="mr-1"></b-icon>
                        <span>{{ $t(artisticProfile.fee_type)}}</span>

                        <ul v-if="types.fee_type_fixed === artisticProfile.fee_type">
                            <li class="mt-3" v-for="feeOption in artisticProfile.fee_options" :key="feeOption.id">
                                {{ feeOption.description ? `${feeOption.description} :` : ''}} {{ feeOption.currency }} {{ feeOption.amount }}
                            </li>
                        </ul>
                    </div>

                    <!-- INSTRUMENTS -->
                    <div class="block" v-if="artisticProfile.instruments.length">
                        <div class="heading mb-3">{{$t('instruments')}}</div>
                        <b-taglist class="is-small">
                            <b-tag v-for="instrument in artisticProfile.instruments"
                                   :key="'artisticProfile-' + artisticProfile.id + '-instrument-' + instrument.id">
                                {{$t(instrument.name)}}
                            </b-tag>
                        </b-taglist>
                    </div>

                    <!-- STYLISTIC OPTIONS -->
                    <div class="block" v-if="artisticProfile.stylistic_options.length">
                        <div class="heading mb-3">{{$t('styles')}}</div>
                        <b-taglist>
                            <b-tag v-for="stylisticOption in artisticProfile.stylistic_options"
                                   :key="'artisticProfile-' + artisticProfile.id + '-option-' + stylisticOption.id">
                                {{$t(stylisticOption.name)}}
                            </b-tag>
                        </b-taglist>
                    </div>

                    <!-- FEATURES -->
                    <div class="block" v-if="artisticProfile.features && artisticProfile.features.length">
                        <div class="heading mb-3">{{$t('features')}}</div>
                        <b-taglist>
                            <b-tag v-for="feature in artisticProfile.features"
                                   :key="'artisticProfile-' + artisticProfile.id + '-feature-' + feature.id">
                                {{$t(feature.type)}}
                            </b-tag>
                        </b-taglist>
                    </div>

                </div>
                <div class="card-footer">
                    <a class="card-footer-item" @click="editProfile(artisticProfile)">{{$t('edit')}}</a>
                </div>

            </div>

<!--            <div class="level is-mobile" >
                <div class="level-left">
                    <div class="level-item">
                    </div>
                    <div class="level-item" v-for="limit in artisticProfile.limits" :key="limit.id">
                        {{ limit.name}}: {{limit.pivot.value_left}}/{{limit.value}}
                    </div>
                </div>
                <div class="level-right">

                </div>
            </div>-->
        </div>
    </div>
</template>

<script>
    import {mapActions, mapMutations, mapState} from "vuex";
    import {CREATE_BLANK_ARTISTIC_PROFILE} from "@/store/modules/artisticProfiles/actions-types";
    import {SET_EDITED_ARTISTIC_PROFILE} from "@/store/modules/artisticProfiles/mutation-types";
    import subscriptionDisabledMixin from "@/mixins/subscriptionDisabledMixin";

    export default {
        name: "ArtisticProfilesList",
        methods: {
            ...mapActions('artisticProfiles', [
                CREATE_BLANK_ARTISTIC_PROFILE,
            ]),
            ...mapMutations('artisticProfiles', [
                SET_EDITED_ARTISTIC_PROFILE,
            ]),
            createNewProfile(){
              this.$Progress.start();
              this[CREATE_BLANK_ARTISTIC_PROFILE]()
              .then(res => {
                this.$router.push({name: 'editArtisticProfile', params: {id: res.data.id}});
              }).catch(() => {
                this.$Progress.fail();
              }).then(() => {
                this.$Progress.finish();
              });
            },
            editProfile(profile){
                this.$router.push({
                    name: 'editArtisticProfile',
                    params: {
                        id: profile.id,
                        profile: profile,
                    }
                });
            },
        },
        computed: {
            ...mapState('artisticProfiles', {
                profiles: state => state.profiles,
            }),
            types(){
                return this.$store.state.base.applicationTypes;
            },
        },
        mixins: [subscriptionDisabledMixin],
    };
</script>

<style scoped lang="scss">
    .artistic-profile-item-image{
        max-height: 400px;
    }
    .card-image {
        figure {
            max-height: 700px;
            overflow: hidden;
        }
    }
</style>