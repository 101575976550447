import { render, staticRenderFns } from "./ArtisticProfiles.vue?vue&type=template&id=abf4d1fc&scoped=true&"
import script from "./ArtisticProfiles.vue?vue&type=script&lang=js&"
export * from "./ArtisticProfiles.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abf4d1fc",
  null
  
)

export default component.exports