<template>

    <div class="artistic-profiles" >

        <artist-dashboard-navigation />

        <artistic-profiles-list  />

        <laravel-pagination :pagination-meta="paginationMeta" @change="getProfiles" />

    </div>
</template>

<script>
    import {mapActions, mapMutations, mapState} from "vuex";
    import {
        CREATE_BLANK_ARTISTIC_PROFILE, DESTROY_ARTISTIC_PROFILE,
        GET_ARTISTIC_PROFILES
    } from "@/store/modules/artisticProfiles/actions-types";
    import {
        SET_EDITED_ARTISTIC_PROFILE
    } from "@/store/modules/artisticProfiles/mutation-types";
    import {GET_DATA_FOR_CREATION} from "@/store/modules/base/action-types";
    import ArtisticProfilesList from "@/components/Artist/ArtisticProfilesList";
    import ArtistDashboardNavigation from "@/components/Artist/DashboardNavigation";
    import LaravelPagination from "@/components/LaravelPagination";

    export default {
        name: "ArtisticProfiles",
        title(){ return this.$t('Artistic profiles');},
        components: {ArtistDashboardNavigation, ArtisticProfilesList, LaravelPagination, },
        data: function(){
            return {
                paginationMeta: {},
            };
        },
        methods:{
            ...mapActions('artisticProfiles', [
                GET_ARTISTIC_PROFILES,
                CREATE_BLANK_ARTISTIC_PROFILE,
                DESTROY_ARTISTIC_PROFILE,
            ]),
            ...mapActions('base', [
                GET_DATA_FOR_CREATION,
            ]),
            ...mapMutations('artisticProfiles', [
                SET_EDITED_ARTISTIC_PROFILE
            ]),
            createNewProfile(){
                this[CREATE_BLANK_ARTISTIC_PROFILE]();
            },
            getProfiles(page = 1){
                this.$Progress.start();

                this[GET_ARTISTIC_PROFILES]({page}).then(res => {
                    this.$Progress.finish();
                    this.paginationMeta = res.data.meta;
                }).catch(() => {
                    this.$Progress.fail();
                });
            },

        },
        computed: {
            ...mapState('artisticProfiles', [
                'profiles',
                'editedProfile'
            ]),
            artisticProfilesCount(){
                return this.$store.state.artisticProfiles.profiles.length;
            }
        },
        created() {
            this.getProfiles();
        }
    };
</script>

<style scoped>

</style>